<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :before-close="closed"
    width="1000px"
    top="5vh"
  >
    <el-steps :active="active" align-center finish-status="success">
      <el-step title="上传文件" />
      <el-step title="数据预览" />
      <el-step title="导入数据" />
    </el-steps>
    <template v-if="active === 1">
      <div class="upload-tips">
        <div class="icon">
          <i class="el-icon-edit-outline text-48" />
        </div>
        <div class="content">
          <div>填写导入数据信息</div>
          <div>
            请按照数据模板的格式准备导入数据，模板中的表头名称不可更改，表头行不能删除
          </div>
          <div v-if="templateUrl">
            <el-button
              type="text"
              @click="handleTemplateDownload"
            >下载模版</el-button>
          </div>
        </div>
      </div>
      <div class="upload-tips">
        <div class="icon">
          <i class="el-icon-upload text-48" />
        </div>
        <div class="content">
          <div>上传填好的信息表</div>
          <div>
            文件后缀名必须为xls或xlsx(即Excel格式),文件大小不不得大于10M,最多支持导入3000条数据
          </div>
          <div>
            <upload
              ref="upload"
              :http-request="changeFile"
              :limit="1"
              :value="fileList"
              accept=".xls,.xlsx"
              list-type="text"
              style="display: inline-block"
              @remove="removeFile"
            >
              <el-button
                type="text"
                :loading="submitLoading"
              >上传文件</el-button>
            </upload>
          </div>
        </div>
      </div>
      <div class="upload-tips">
        <div class="icon">
          <i class="el-icon-warning text-48" />
        </div>
        <div class="content">
          <div>特别提示</div>
          <div>
            导入过程中如发现个别数据校验不通过，则全量回滚修正后再重新操作导入
          </div>
        </div>
      </div>
    </template>
    <template v-if="active === 2">
      <div class="upload-tips mb-2">
        <div class="icon">
          <i class="el-icon-view text-48" />
        </div>
        <div class="content">
          <div class="normal">
            正常数量条数:
            <span>
              {{ successNum }}
            </span>
          </div>
          <div class="abnormal">
            异常数量条数:
            <span>
              {{ errorNum }}
            </span>
            <el-button
              v-if="errorFileUrl"
              type="text"
              @click="handleErrorDownload"
            >下载异常文件</el-button>
          </div>
        </div>
      </div>
      <vxe-grid v-bind="gridOptions" :data="tableData" />
    </template>
    <template v-if="active === 3">
      <div v-if="submitLoading" class="progress-block">
        <el-progress
          style="width: 50%"
          :stroke-width="16"
          :percentage="percentage"
          :color="colors"
        />
        <span class="mt-8">正在导入数据</span>
      </div>
      <el-result
        v-else-if="isSuccess"
        icon="success"
        title="批量导入完成"
        :sub-title="`您已成功导入${tableData.length}条数据`"
      />
      <el-result
        v-else
        icon="error"
        title="批量导入失败"
        sub-title="请检查数据后重新导入"
      />
    </template>
    <template #footer>
      <span class="dialog-footer">
        <el-button v-show="active === 1" @click="closed">取消</el-button>
        <el-button
          v-show="active === 1"
          type="primary"
          :loading="submitLoading"
          @click="handleUpload"
        >下一步</el-button>
        <el-button
          v-show="active === 2"
          :loading="submitLoading"
          @click="active = 1"
        >上一步</el-button>
        <el-button
          v-show="active === 2"
          type="primary"
          :loading="submitLoading"
          @click="handleSubmit"
        >确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import Upload from '@/components/Upload'

export default {
  components: {
    Upload
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '批量导入'
    },
    options: {
      type: Object,
      default: () => ({
        templateUrl: '',
        importExcel: Function.prototype,
        sureImportExcel: Function.prototype,
        gridOptions: Object.prototype
      })
    }
  },
  data() {
    return {
      fileList: [],
      tableData: [],
      submitLoading: false,
      isSuccess: false,
      percentage: 0,
      successNum: 0,
      errorNum: 0,
      errorFileUrl: '',
      active: 1,
      colors: [
        { color: '#f56c6c', percentage: 40 },
        { color: '#e6a23c', percentage: 80 },
        { color: '#5cb87a', percentage: 100 }
      ]
    }
  },
  computed: {
    gridOptions() {
      return {
        border: true,
        resizable: true,
        showOverflow: true,
        height: 300,
        ...this.options.gridOptions
      }
    },
    templateUrl() {
      return this.options.templateUrl
    }
  },
  methods: {
    async handleUpload() {
      try {
        if (!this.fileList.length) {
          this.$message.warning('请上传文件')
          return
        }
        this.submitLoading = true
        const params = new FormData()
        params.append('file', this.fileList[0].file)
        const { success, datas, message } = await this.options
          .importExcel(params)
          .finally(() => {
            this.submitLoading = false
          })

        if (success) {
          const { errorResult, successResult } = datas
          this.tableData = successResult?.importDateList || []
          this.successNum =
            errorResult?.successAccount ?? this.tableData.length
          this.errorNum = errorResult?.errorAccount ?? 0
          this.errorFileUrl = errorResult?.errorFileUrl ?? ''
          this.active = 2
        } else {
          this.$message.warning(message)
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleTemplateDownload() {
      window.open(this.templateUrl, '_blank')
    },
    async handleSubmit() {
      if (!this.tableData.length) {
        this.$message.warning('请先上传文件')
        return
      }
      this.active = 3
      this.percentage = 0
      this.submitLoading = true
      const { success, message } = await this.options
        .sureImportExcel({
          requestList: this.tableData
        })
        .finally(() => {
          this.submitLoading = false
        })

      const timer = setInterval(() => {
        this.percentage = (this.percentage % 100) + 10
        if (this.percentage >= 100) {
          this.percentage = 99
        }
      }, 500)

      clearInterval(timer)

      this.percentage = 100

      if (success) {
        this.$message.success('导入成功')
        this.$emit('refresh')
        this.isSuccess = true
      } else {
        this.isSuccess = false
        this.$message.warning(message)
      }
    },
    handleErrorDownload() {
      const url = this.errorFileUrl.replace(/^http:\/\//, 'https://')
      window.open(url, '_blank')
    },
    closed() {
      this.active = 1
      this.tableData = []
      this.percentage = this.successNum = this.errorNum = 0
      this.isSuccess = false
      this.removeFile()
      this.$emit('update:visible', false)
    },
    changeFile({ file }) {
      this.fileList.push({ file, name: file.name })
    },
    removeFile() {
      this.fileList = []
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-upload-list__item:first-child {
  margin-top: 0;
}
.upload-tips {
  display: flex;
  height: 7rem;
  margin-top: 10px;
  .icon {
    width: 7rem;
    background-color: rgba(229, 229, 229, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem;
    & > :nth-child(1) {
      font-weight: bold;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      font-size: 1rem;
    }
    & > :nth-child(2) {
      color: rgba(107, 114, 128, 0.7);
      font-size: 0.875rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }
    & > :nth-child(3) {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }
    .normal,
    .abnormal {
      font-weight: normal;
      font-size: 14px;
      color: #606266;
    }
    .normal span {
      color: greenyellow;
    }
    .abnormal span {
      color: red;
    }
  }
}

.progress-block {
  height: 24rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mt-10 {
  margin-top: 10px;
}
.text-48 {
  font-size: 48px;
}
</style>
